import React from "react";
import { SynapiItems } from "../SynapiItems/SynapiItems";
import "./MediaComponent.scss";
export const MediaComponent = (props) => {
    const url = "https://app.syneview.fr";
    const objectFit = props.objectFit || "cover";
    return props.media.type === "video" ? (React.createElement("video", { className: `MediaComponent video ${objectFit} ${props.className || ""}`, src: props.media.url || `${url}/uploads/medias/${props.media._id}/original.${props.media.fileExtension}`, autoPlay: props.library ? false : true, loop: props.library ? false : true }))
        : props.media.type === "image" ? (React.createElement("img", { className: `MediaComponent image ${objectFit} ${props.className || ""}`, src: props.media.url || `${url}/uploads/medias/${props.media._id}/original.${props.media.fileExtension}`, alt: "" }))
            : props.media.type === "iframe" ? (React.createElement("iframe", { className: `MediaComponent iframe ${objectFit} ${props.className || ""}`, src: props.media.url || "" }))
                : props.media.type === "youtube" ? (React.createElement("iframe", { className: `MediaComponent youtube ${objectFit} ${props.className || ""}`, width: "560", height: "315", src: `https://www.youtube.com/embed/${props.media.url}${props.library
                        ? ""
                        : "?autoplay=1&loop=1&controls=0&listType=user_uploads&modestbranding=1&rel=0&showinfo=0&mute=1"}`, title: "YouTube video player", frameBorder: "0", allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true }))
                    : props.media.type === "synapi" ? (React.createElement(SynapiItems, { media: props.media, duration: props.library ? 1000 : props.duration ? props.duration * 1000 : undefined, library: props.library, objectFit: objectFit, className: props.className, items: props.items })) : React.createElement("p", { className: "MediaComponent error" }, "Erreur");
};
