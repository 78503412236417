import React, { useCallback, useEffect, useRef, useState } from "react";
import { Layout } from "../../components/Layout/Layout";
import "./style.scss";
import { Broadcast } from "../../components/Broadcast/Broadcast";
import { Promise } from "true-promise";
import { requestItems } from "../../utils/requestItems";
import { shuffle } from "../../utils/shuffle";
import { WidgetComponent } from "../../components/Widget/Widget";
let timeout = null;
let datesInterval = null;
let getInterval = null;
let loadingBroadcast = true;
let loadingBroadcastGroups = true;
const maxCallDepth = 3;
const timeIntervalComputeBroadcasts = 15;
const timeIntervalRefreshRepositories = 30;
console.log("VERSION 1.0.0");
const Main = (view) => {
    const [state, setState] = useState({
        broadcastGroups: [],
        maximumRNGNumber: 0,
        currentBroadcastGroup: null,
        currentBroadcast: null,
        currentBroadcastIndex: -1,
        nextBroadcastGroup: null,
    });
    const stateRef = useRef(state);
    stateRef.current = state;
    const reload = () => {
        setTimeout(() => {
            location.reload();
        });
    };
    const processBroadcastGroups = useCallback(() => {
        console.log("🌼 processBroadcastGroups");
        if (view.session.user) {
            const dateNow = new Date(Date.now());
            const weekdayNow = dateNow.toLocaleDateString("en", { weekday: "long" }).toLowerCase();
            const timeNow = dateNow.getTime();
            const minutesNow = dateNow.getHours() * 60 + dateNow.getMinutes();
            const screenGroups = view.repositories.screenGroup.filter(sg => view.session.user
                && sg.screens.includes(view.session.user._id));
            console.log("BROADCAST GROUPS", view.repositories.broadcastGroup.length, view.repositories.broadcastGroup);
            const broadcastGroups = view.repositories.broadcastGroup.filter(bg => view.session.user
                && bg.playing
                && (bg.screens.includes(view.session.user._id)
                    || screenGroups.some(sg => bg.screenGroups.includes(sg._id)))
                && timeNow >= bg.start.getTime()
                && (!bg.end
                    || timeNow <= bg.end.getTime())
                && ((!bg.timeSlots || bg.timeSlots.length === 0)
                    || (bg.timeSlots.some(ts => ts.day === weekdayNow
                        && ts.start <= minutesNow
                        && ts.end >= minutesNow))));
            console.log("FILTERED BROADCAST GROUPS", broadcastGroups.length, broadcastGroups);
            let index = 1;
            const processedBroadcastGroupsPromises = broadcastGroups.map(bg => new Promise((resolve, reject) => {
                const broadcastGroupMinRNG = index;
                const broadcastGroupMaxRNG = broadcastGroupMinRNG + bg.weight - 1;
                const broadcastPromises = bg.broadcasts.map(b => new Promise((resolve, reject) => {
                    const media = view.repositories.media.findById(b.media);
                    if (!media)
                        return reject();
                    if (media.type !== "synapi") {
                        resolve({
                            media,
                            duration: b.duration,
                            objectFit: b.objectFit
                        });
                    }
                    else {
                        requestItems(media.url || "", media.filter_id || 0, media.imageFilter)
                            .then(items => {
                            resolve({
                                media,
                                duration: b.duration,
                                objectFit: b.objectFit,
                                items,
                            });
                        })
                            .catch(reject);
                    }
                }));
                Promise.allSettled(broadcastPromises)
                    .then(pb => {
                    const processedBroadcasts = pb.filter((pb) => pb.status === "resolved").map((pb) => pb.value);
                    console.log("PROCESSED BROADCASTS", processedBroadcasts.length, processedBroadcasts);
                    loadingBroadcast = false;
                    resolve({
                        ...bg,
                        broadcasts: processedBroadcasts,
                        minRNG: broadcastGroupMinRNG,
                        maxRNG: broadcastGroupMaxRNG,
                    });
                });
                index += bg.weight;
            }));
            Promise.allSettled(processedBroadcastGroupsPromises)
                .then(pbg => {
                const processedBroadcastGroups = pbg.filter((p) => p.status === "resolved").map((p) => p.value);
                console.log("PROCESSED BROADCAST GROUPS", processedBroadcastGroups.length, processedBroadcastGroups);
                loadingBroadcastGroups = false;
                setState({
                    ...stateRef.current,
                    broadcastGroups: processedBroadcastGroups,
                    maximumRNGNumber: index - 1,
                    currentBroadcastIndex: -1,
                    currentBroadcast: null,
                    currentBroadcastGroup: null,
                });
                setTimeout(moveToNextBroadcast);
            });
        }
    }, [
        view.repositories.broadcastGroup,
        view.repositories.screenGroup,
        view.repositories.user,
        view.repositories.media,
        view.session.user
    ]);
    const chooseNextBroadcastGroup = useCallback((callDepth) => {
        console.log("🌼 chooseNextBroadcastGroup");
        const randomNumber = Math.floor(Math.random() * stateRef.current.maximumRNGNumber) + 1;
        console.log("random Number", randomNumber);
        let broadcastGroup = stateRef.current.broadcastGroups.find(bg => bg.minRNG <= randomNumber &&
            randomNumber <= bg.maxRNG);
        console.log("Randomly choosen BG", broadcastGroup?.name);
        if (!broadcastGroup) {
            if (!callDepth || callDepth < maxCallDepth) {
                return chooseNextBroadcastGroup(callDepth ? callDepth + 1 : 1);
            }
            else {
                broadcastGroup = stateRef.current.broadcastGroups[0];
            }
        }
        if (!broadcastGroup) {
            reload();
        }
        const newBroadcastGroupInstance = {
            ...broadcastGroup,
            broadcasts: broadcastGroup.mode === "ordered" ? [...broadcastGroup.broadcasts] : shuffle(broadcastGroup.broadcasts)
        };
        console.log("new broadcast group instance", newBroadcastGroupInstance?.name);
        setState({
            ...stateRef.current,
            nextBroadcastGroup: newBroadcastGroupInstance
        });
    }, []);
    const moveToNextBroadcastGroup = useCallback(() => {
        console.log("\n\n\n");
        console.log("🌺 moveToNextBroadcastGroup");
        console.log("broadcastGroups", stateRef.current.broadcastGroups.length);
        setState({
            ...stateRef.current,
            currentBroadcastGroup: stateRef.current.nextBroadcastGroup,
            nextBroadcastGroup: null,
            currentBroadcastIndex: -1
        });
        chooseNextBroadcastGroup();
        setTimeout(moveToNextBroadcast);
    }, []);
    const moveToNextBroadcast = useCallback(() => {
        console.log("🌸 moveToNextBroadcast");
        clearTimeout(timeout);
        timeout = null;
        console.log("current broadcast group", stateRef.current.currentBroadcastGroup?.name);
        if (!stateRef.current.currentBroadcastGroup) {
            if (stateRef.current.broadcastGroups.length > 0) {
                moveToNextBroadcastGroup();
                return;
            }
        }
        const currentBroadcastIndex = stateRef.current.currentBroadcastIndex + 1;
        if (stateRef.current.currentBroadcastGroup && currentBroadcastIndex >= stateRef.current.currentBroadcastGroup.broadcasts.length) {
            console.log("TOUTES LES DIFFUSION AFFICHEES, PASSAGE AU GROUPE SUIVANT");
            if (stateRef.current.broadcastGroups.length > 0) {
                moveToNextBroadcastGroup();
                return;
            }
        }
        const currentBroadcast = stateRef.current.currentBroadcastGroup?.broadcasts[currentBroadcastIndex];
        if (!currentBroadcast) {
            if (stateRef.current.broadcastGroups.length > 0) {
                moveToNextBroadcastGroup();
                return;
            }
        }
        if (!loadingBroadcast && !loadingBroadcastGroups && (currentBroadcastIndex === -1 || (currentBroadcastIndex == 0 && !currentBroadcast))) {
            reload();
        }
        if (currentBroadcast) {
            console.log("DISPLAY", currentBroadcast.media.name);
            setState({
                ...stateRef.current,
                currentBroadcastIndex,
                currentBroadcast
            });
            const timeoutDuration = currentBroadcast.media.type === "synapi" && currentBroadcast.items
                ? (currentBroadcast.items.length || 1) * (currentBroadcast.duration || 1) * 1000
                : (currentBroadcast.duration || 1) * 1000;
            console.log("timeoutDuration", timeoutDuration);
            clearTimeout(timeout);
            timeout = null;
            timeout = setTimeout(moveToNextBroadcast, timeoutDuration);
        }
    }, []);
    useEffect(() => {
        console.log("🌼 useEffect processBroadcastGroups");
        processBroadcastGroups();
        clearInterval(datesInterval);
        datesInterval = null;
        datesInterval = setInterval(() => {
            console.log("🌼 processBroadcastGroups INTERVAL");
            processBroadcastGroups();
        }, timeIntervalComputeBroadcasts * 60 * 1000);
        return () => {
            if (datesInterval) {
                clearInterval(datesInterval);
                datesInterval = null;
            }
        };
    }, [
        view.repositories.broadcastGroup,
        view.repositories.screenGroup,
        view.repositories.user,
        view.repositories.media,
        view.session.user
    ]);
    useEffect(() => {
        getInterval = setInterval(() => {
            view.repositories.broadcastGroup.getAll().send();
            view.repositories.screenGroup.getAll().send();
            view.repositories.user.getAll().send();
            view.repositories.media.getAll().send();
            view.repositories.organization.getAll().send();
        }, timeIntervalRefreshRepositories * 60 * 1000);
        return () => {
            if (getInterval) {
                clearInterval(getInterval);
                getInterval = null;
            }
        };
    }, []);
    const screen = view.repositories.user.find(s => s._id === view.session?.user?._id);
    console.log("🌐 SCREEN", screen);
    return (React.createElement("div", { className: "Player" },
        state.currentBroadcast && React.createElement(Broadcast, { broadcast: state.currentBroadcast }),
        screen && screen.widgets && screen.widgets.length > 0 && screen.widgets.map((widget, i) => (React.createElement("div", { className: `widgetContainer v-${widget.verticalAlignment} h-${widget.horizontalAlignment}` },
            React.createElement("div", { className: `secondWidgetContainer`, style: {
                    marginTop: widget.verticalAlignment === "top" || widget.verticalAlignment === "center" ? `${widget.verticalMargin}px` : "0px",
                    marginBottom: widget.verticalAlignment === "bottom" ? `${widget.verticalMargin}px` : "0px",
                    marginLeft: widget.horizontalAlignment === "left" || widget.horizontalAlignment === "center" ? `${widget.horizontalMargin}px` : "0px",
                    marginRight: widget.horizontalAlignment === "right" ? `${widget.horizontalMargin}px` : "0px",
                    fontSize: `${widget.fontSize}px`
                } },
                React.createElement(WidgetComponent, { widget: widget }))))),
        React.createElement(Layout, null,
            React.createElement("div", { className: "debug" },
                React.createElement("div", null,
                    React.createElement("h5", null, "Current media"),
                    React.createElement("p", null,
                        React.createElement("span", null, "Name:"),
                        " ",
                        state.currentBroadcast?.media.name),
                    React.createElement("p", null,
                        React.createElement("span", null, "Type:"),
                        state.currentBroadcast?.media.type),
                    React.createElement("p", null,
                        React.createElement("span", null, "File extension:"),
                        state.currentBroadcast?.media.fileExtension),
                    state.currentBroadcast?.media.duration && React.createElement("p", null,
                        React.createElement("span", null, "Duration:"),
                        state.currentBroadcast?.media.duration,
                        "s")),
                React.createElement("div", null,
                    React.createElement("h5", null, "Current Broadcast"),
                    React.createElement("p", null,
                        React.createElement("span", null, "Current broadcast index inside its group:"),
                        state.currentBroadcastIndex),
                    React.createElement("p", null,
                        React.createElement("span", null, "Duration:"),
                        state.currentBroadcast?.duration),
                    React.createElement("p", null,
                        React.createElement("span", null, "ObjectFit:"),
                        " ",
                        state.currentBroadcast?.objectFit)),
                React.createElement("div", null,
                    React.createElement("h5", null, "Current Broadcast Group"),
                    React.createElement("p", null,
                        React.createElement("span", null, "Name:"),
                        state.currentBroadcastGroup?.name),
                    React.createElement("p", null,
                        React.createElement("span", null, "Weight:"),
                        state.currentBroadcastGroup?.weight),
                    React.createElement("p", null,
                        React.createElement("span", null, "MinRNG:"),
                        state.currentBroadcastGroup?.minRNG),
                    React.createElement("p", null,
                        React.createElement("span", null, "MaxRNG:"),
                        state.currentBroadcastGroup?.maxRNG),
                    React.createElement("p", null,
                        React.createElement("span", null, "Mode:"),
                        state.currentBroadcastGroup?.mode),
                    React.createElement("p", null,
                        React.createElement("span", null, "Start date:"),
                        state.currentBroadcastGroup?.start.toDateString()),
                    React.createElement("p", null,
                        React.createElement("span", null, "End date:"),
                        state.currentBroadcastGroup?.end?.toDateString()),
                    React.createElement("p", null,
                        React.createElement("span", null, "Screens amount:"),
                        state.currentBroadcastGroup?.screens.length),
                    React.createElement("p", null,
                        React.createElement("span", null, "Screen groups amount:"),
                        state.currentBroadcastGroup?.screenGroups.length),
                    React.createElement("p", null,
                        React.createElement("span", null, "Broadcasts amount:"),
                        state.currentBroadcastGroup?.broadcasts.length)),
                React.createElement("div", null,
                    React.createElement("h5", null, "Next Broadcast Group"),
                    React.createElement("p", null,
                        React.createElement("span", null, "Name:"),
                        state.nextBroadcastGroup?.name),
                    React.createElement("p", null,
                        React.createElement("span", null, "Weight:"),
                        state.nextBroadcastGroup?.weight),
                    React.createElement("p", null,
                        React.createElement("span", null, "MinRNG:"),
                        state.nextBroadcastGroup?.minRNG),
                    React.createElement("p", null,
                        React.createElement("span", null, "MaxRNG:"),
                        state.nextBroadcastGroup?.maxRNG),
                    React.createElement("p", null,
                        React.createElement("span", null, "Mode:"),
                        state.nextBroadcastGroup?.mode),
                    React.createElement("p", null,
                        React.createElement("span", null, "Start date:"),
                        state.nextBroadcastGroup?.start.toDateString()),
                    React.createElement("p", null,
                        React.createElement("span", null, "End date:"),
                        state.nextBroadcastGroup?.end?.toDateString()),
                    React.createElement("p", null,
                        React.createElement("span", null, "Screens amount:"),
                        state.nextBroadcastGroup?.screens.length),
                    React.createElement("p", null,
                        React.createElement("span", null, "Screen groups amount:"),
                        state.nextBroadcastGroup?.screenGroups.length),
                    React.createElement("p", null,
                        React.createElement("span", null, "Broadcasts amount:"),
                        state.nextBroadcastGroup?.broadcasts.length)),
                React.createElement("div", null,
                    React.createElement("h5", null, "Infos"),
                    React.createElement("p", null,
                        React.createElement("span", null, "maximumRNGNumber:"),
                        state.maximumRNGNumber),
                    React.createElement("p", null,
                        React.createElement("span", null, "Broadcast groups amount (state):"),
                        state.broadcastGroups.length),
                    React.createElement("p", null,
                        React.createElement("span", null, "Broadcast groups amount (repo):"),
                        view.repositories.broadcastGroup.length))))));
};
export default Main;
