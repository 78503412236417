import Player from "./Player";
import Connexion from "./Connexion";
const views = [
    {
        path: ["", "/", "index.html", "/index.html"],
        view: Player,
        title: "Player",
        className: "Player",
        auth: true
    },
    {
        path: ["connexion"],
        view: Connexion,
        title: "Connexion",
        className: "Connexion",
        default: true
    },
];
export default views;
