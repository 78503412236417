import React, { useCallback, useContext, useRef, useState } from "react";
import { faSignOutAlt, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import "./Layout.scss";
import { Button, Context } from "sunflowers";
let timeout = null;
export const Layout = (props) => {
    const context = useContext(Context);
    const [state, setState] = useState({
        show: false
    });
    const stateRef = useRef(state);
    stateRef.current = state;
    const clear = useCallback(() => {
        if (timeout !== null) {
            window.clearTimeout(timeout);
            timeout = null;
        }
    }, []);
    const handleMove = useCallback(() => {
        if (!stateRef.current.show) {
            setState({ ...stateRef.current, show: true });
        }
        clear();
        timeout = window.setTimeout(() => {
            clear();
            setState({ ...stateRef.current, show: false });
        }, 2000);
    }, []);
    return (React.createElement("div", { className: `Layout${!state.show ? " hidden" : ""}`, onMouseMove: handleMove },
        React.createElement("div", { className: `topBanner` },
            React.createElement("p", null, context.session?.user?.userName),
            React.createElement("div", { className: "buttons" },
                React.createElement(Button, { icon: faSyncAlt, onClick: () => {
                        location.reload();
                    }, className: "refreshButton" }),
                React.createElement(Button, { icon: faSignOutAlt, onClick: () => {
                        context.session?.logout();
                        setTimeout(() => context.router.navigate("connexion"));
                    }, className: "logoutButton" }))),
        props.children && (React.createElement("div", { className: `bottomBanner` }, props.children))));
};
