import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Context } from "sunflowers";
import "./SynapiItems.scss";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MediaComponent } from "../MediaComponent/MediaComponent";
import { requestItems } from "../../utils/requestItems";
let requestTimeout;
let displayTimeout;
export const SynapiItems = (props) => {
    const context = useContext(Context);
    const [state, setState] = useState({
        items: props.items || [],
        currentDisplayedItemIndex: 0,
    });
    const stateRef = useRef(state);
    stateRef.current = state;
    const getItems = useCallback(() => {
        console.log("get Items");
        if (!props.items) {
            requestItems(props.media.url || "", props.media.filter_id || 0, props.media.imageFilter)
                .then(items => {
                setState({ ...stateRef.current, items });
            })
                .catch(() => context.notify && context.notify("Erreur", "red"));
        }
        if (requestTimeout)
            clearTimeout(requestTimeout);
        requestTimeout = setTimeout(getItems, 5 * 60 * 1000);
    }, []);
    const nextItem = useCallback(() => {
        const length = stateRef.current.items.length;
        const newIndex = stateRef.current.currentDisplayedItemIndex >= length - 1
            ? 0
            : stateRef.current.currentDisplayedItemIndex + 1;
        setState({ ...stateRef.current, currentDisplayedItemIndex: newIndex });
        if (displayTimeout)
            clearTimeout(displayTimeout);
        displayTimeout = setTimeout(nextItem, props.duration);
    }, []);
    useEffect(() => {
        getItems();
        nextItem();
    }, [props.media, props.items]);
    useEffect(() => {
        console.log("use effect items");
        if (props.items)
            setState({ ...stateRef.current, items: props.items });
    }, [props.items]);
    const item = stateRef.current.items[stateRef.current.currentDisplayedItemIndex];
    const commune = item?.AdressePrincipale?.Commune;
    const date = item?.TypeFiche === "FETE_ET_MANIFESTATION" ? item?.NextOuverturePeriode?.Start : undefined;
    const day = date?.toLocaleDateString("fr", { day: "numeric" });
    const month = date?.toLocaleDateString("fr", { month: "long" });
    const year = date?.toLocaleDateString("fr", { year: "numeric" });
    const topImage = context.repositories?.media.find(m => m._id === props.media.topImage_id);
    const bottomImage = context.repositories?.media.find(m => m._id === props.media.bottomImage_id);
    console.log(props.media.name, stateRef.current.currentDisplayedItemIndex, stateRef.current.items.length);
    return (React.createElement("div", { className: `MediaComponent synapi SynapiItem ${props.objectFit} ${props.library && "library"} ${props.className || ""}` },
        topImage && React.createElement(MediaComponent, { media: topImage, className: "topImage" }),
        React.createElement("div", { className: "main" }, props.library ? (React.createElement("img", { className: "synapiImage", src: item?.ImageUrl || "", alt: "" })) : (React.createElement(React.Fragment, null,
            React.createElement("p", { className: "name" }, props.media.name),
            item?.Title && React.createElement("p", { className: "title" }, item?.Title),
            commune && React.createElement("p", { className: "location" },
                React.createElement(FontAwesomeIcon, { icon: faMapMarkerAlt }),
                commune),
            date && (React.createElement("div", { className: "date" },
                React.createElement("span", { className: "day" }, day),
                React.createElement("span", { className: "month" }, month),
                React.createElement("span", { className: "year" }, year))),
            React.createElement("div", { className: "imageContainer" },
                React.createElement("img", { className: "synapiImage", src: item?.ImageUrl || "", alt: "" }))))),
        bottomImage && React.createElement(MediaComponent, { media: bottomImage, className: "topImage" })));
};
