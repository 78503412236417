import React, { useCallback, useEffect } from "react";
import { Button, Input } from "sunflowers";
import "./style.scss";
const Main = (view) => {
    const playerConnect = useCallback((key) => {
        view.emit("playerConnect", { key })
            .then((response) => {
            view.notify("Connecté !", "green");
            view.session.saveSession(response.token, response.user);
            setTimeout(() => {
                view.router.navigate("/");
            }, []);
        })
            .catch((_response) => {
            view.update({ message: "Echec de connexion" });
            view.notify("Echec de connexion", "red");
        });
    }, []);
    useEffect(() => { view.router.params.cle && playerConnect(view.router.params.cle); }, [view.router.params.cle]);
    useEffect(() => { view.router.params.key && playerConnect(view.router.params.key); }, [view.router.params.key]);
    return (React.createElement("div", { className: "ConnexionView" },
        React.createElement("div", null,
            React.createElement("h3", null, "Syneview"),
            view.store.message && (React.createElement("p", { className: "errorMessage" }, view.store.message)),
            React.createElement(Input, { value: view.store.key, onInputChange: (key) => view.update({ key }), label: "Cl\u00E9", name: "key" }),
            React.createElement(Button, { name: "Connexion", filled: true, className: "actionButton", onClick: () => playerConnect(view.store.key) }))));
};
export default Main;
